<template>
  <b-card>
    <!-- ref="refreshCard"
    action-refresh
    @refresh="refreshStop('refreshCard')" -->
    <b-row>
      <b-col md="12" sm="12" class="my-1">
        <b-button-group>
          <b-button @click.prevent="add" variant="primary">
            <feather-icon icon="PlusIcon" class="mr-50" />
            {{ $t('Add') }}
          </b-button>
          <!-- <b-button  @click.prevent="maskategori" variant="success">
            <feather-icon icon="PlusIcon" class="mr-50" />
            {{ $t('Master Category') }}
          </b-button> -->
        </b-button-group>
        <b-modal v-model="modalmasteradd" id="modal-tambah" cancel-variant="secondary" ok-title="Add"
          cancel-title="Batal" centered title="Form Add">
          <b-form>
            <b-form-group>
              <b-form-input v-model="master_kategori" id="kategori" placeholder="Nama kategori" />
            </b-form-group>
          </b-form>

          <template #modal-footer>
            <section class="d-flex justify-content-end align-items-center">
              <b-button size="sm" class="mr-1" variant="primary" @click="submit">
                Add
              </b-button>
              <b-button size="sm" variant="danger" @click="resetForm">
                Batal
              </b-button>
            </section>
          </template>
        </b-modal>
        <b-modal v-model="modalmasteredit" id="modal-ubah" cancel-variant="secondary" ok-:title="$t('Edit')"
          cancel-title="Batal" centered title="Form Ubah">
          <b-form>
            <b-form-group>

              <b-form-input v-model="master_kategori" id="master_kategori" placeholder="Nama kategori" />
            </b-form-group>
          </b-form>

          <template #modal-footer>
            <section class="d-flex justify-content-end align-items-center">
              <b-button size="sm" class="mr-1" variant="info" @click="submit">
                Ubah
              </b-button>
              <b-button size="sm" variant="danger" @click="resetForm">
                Batal
              </b-button>
            </section>
          </template>
        </b-modal>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="2" sm="2" class="my-1">
        <b-form-group class="mb-0" :label="$t('Per page')" label-for="perPage" size="sm">
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" />
        </b-form-group>
      </b-col>
      <b-col md="2" sm="2" class="my-1">
        <b-form-group :label="$t('Sort')" label-size="sm" label-for="sortBySelect" class="mb-0">
          <b-input-group size="sm">
            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="8" sm="6" class="my-1">
        <b-form-group :label="$t('Filter')" label-for="filterInput" class="mb-0" size="sm">
          <b-input-group>
            <b-form-input v-model="filter" placeholder="Tekan enter untuk cari" size="sm"
              @keydown.enter.prevent="getData()"></b-form-input>
            <b-input-group-append>
              <b-button variant="outline-primary" @click="getData()" size="sm">
                Cari
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-overlay :show="loading">
          <b-table striped hover responsive :per-page="perPage" :items="items" :fields="fields">
            <template #cell(no)="row">
              <strong class="text-center">
                {{ (row.index + 1) }}
              </strong>
            </template>
            <template #cell(actions)="row">
              <b-button :title="$t('Edit')" size="sm" @click="edit(row.item)" class="mr-1" variant="outline-info">
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button :title="$t('Delete')" size="sm" @click="remove(row.item)" class="mr-1" variant="outline-danger">
                <feather-icon icon="TrashIcon" />
              </b-button>
            </template>

            <template #row-details="row">
              <b-card>
                <ul>
                  <li v-for="(value, key) in row.item" :key="key">
                    {{ key }}: {{ value }}
                  </li>
                </ul>
              </b-card>
            </template>
          </b-table>
        </b-overlay>
      </b-col>
      <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
        <pre>{{ infoModal.content }}</pre>
      </b-modal>

      <b-col cols="12">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
          class="my-0" />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BTable, VBTooltip,
  BAvatar, BForm,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton, BButtonGroup, BOverlay, BCard,
} from "bootstrap-vue";

export default {
  components: {
    BCardActions, VBTooltip, ToastificationContent,
    BModal, BForm,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton, BButtonGroup, BOverlay, BCard,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      modalmasteradd: false,
      modalmasteredit: false,
      id: null,
      master_kategori: null,
      perPage: 10,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "no",
          label: "No",
        },
        { key: "master_kategori", label: "master kategori", sortable: true },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      loading: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    // Set the initial number of items

    this.getData()

  },
  watch: {
    perPage(value) {
      if (value) {
        this.getData();
      }
    },
    // filter(keyword) {
    //   if (keyword.length > 2 || keyword.length == 0) {
    //     this.getData();
    //   }
    // },
    currentPage(val) {
      if (val) {
        this.getData();
      }
    },
  },
  methods: {
    // maskategori() {
    //   this.$router.push(`/kategori/master`)
    // },
    add() {
      this.id = null
      this.activeAction = 'tambah'
      this.modalmasteradd = true
      this.modalmasteredit = false
    },
    remove(item) {
      this.$swal({
        title: 'Anda yakin?',
        text: `Data kategori "${item.kategori}" ini akan dihapus`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          item.fungsi = 1 // soft delete
          this.$store.dispatch('masterkategori/saveMaster', [item])
            .then(() => {
              // this.pesanBerhasilHapus()
              this.displaySuccess({
                message: 'Berhasil Hapus'
              })
              this.resetForm()
              this.getData()
            })
            .catch(e => {
              // this.pesanGagalHapus()
              this.displayError(e)
              return false
            })
        }
      })
    },
    resetForm() {
      this.id = null
      this.kategori = null
      this.modalmasteradd = false
      this.modalmasteredit = false
    },
    submit() {
      if (this.master_kategori == null || this.master_kategori == '') {
        // this.pesanGagalSimpan()
        this.displayWarning({
          message: 'Gagal menyimpan data, harap cek kembali data yang diisi'
        })
        return false
      }
      let payload = {
        master_kategori: this.master_kategori
      }

      if (this.id) {
        payload.id = this.id
      }

      this.$store.dispatch('masterkategori/saveMaster', [payload])
        .then(() => {
          if (this.activeAction == 'tambah') {
            this.id = null
          }
          this.master_kategori = null
          this.modalmasteradd = false
          this.modalmasteredit = false
          this.getData()
          this.displaySuccess({
            message: 'Berhasil Simpan'
          })
          // this.pesanBerhasilSimpan()
        })

        .catch(e => {
          this.displayError(e)
          return false
        })
    },
    edit(item) {
      // let { id, master_kategori } = item
      this.id = item.id
      this.master_kategori = item.master_kategori
      this.modalmasteredit = true
    },
    getData() {
      this.loading = true
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const payload = {
        search: this.filter != null ? this.filter : null,
        order: this.sortBy ? this.sortBy : null,
        start: currentPage,
        length: this.perPage,
        // filter_by: "nama_lengkap",
        // show_stock: 1,
        // kategori_id: this.category,
      };
      this.$store.dispatch('masterkategori/getDataMaster', payload)
        .then(() => {
          let items = JSON.parse(JSON.stringify(this.$store.state.masterkategori.datas));
          let items_total = this.$store.state.masterkategori.totals;
          this.items = items
          this.totalRows = items_total
          this.loading = false
          // this.items = this.$store.state.kategori.datas
          // this.totalRows = this.items.length;
        })

        .catch(e => {
          this.displayError(e)
          return false
        })
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
